<template>
    <div class="dashboard">
        <h1>類似キーワード{{ contents[contentsType].title }}</h1>
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
            <h2>キーワード名</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt1 l-w5" name="キーワード名" rules="required" tag="div" :immediate="$_getValidatedImmediate()">
                <input v-model="input.keyword_name" type="text" maxlength="20">
                <p class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <h2>類似キーワード</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt1 l-w5" name="類似キーワード" rules="required" tag="div" :immediate="$_getValidatedImmediate()">
                <textarea v-model="arrayKeywords" rows="6" placeholder="キーワード1,キーワード2,キーワード3"></textarea>
                <p class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <div class="l-mt6 l-2-00-m--all">
                <div>
                    <button :class="`${classButtonStyle}cancel`" type="button" @click="$_goBack()">戻る</button>
                    <button :class="X_validatedButtonStyle" :disabled="$_setValidatedDisabled(invalid)" type="button" @click="$_modalOpen(contents[contentsType].modal)">次へ</button>
                </div>
                <div v-if="contents[contentsType].flagShow">
                    <button :class="`${classButtonStyle}delete`" type="button" @click="$_modalOpen('keywordDelete')">削除する</button>
                </div>
            </div>
        </ValidationObserver>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";

    const contents = {
        "create": {
            "flagShow": false,
            "modal": "keywordCreate",
            "title": "登録",
        },
        "update": {
            "flagShow": true,
            "modal": "keywordUpdate",
            "title": "編集",
        },
    };

    export default {
        "mixins": [
            common,
        ],
        "props": {
            "id": {
                "default": "",
                "required": false,
                "type": String,
            },
        },
        data() {
            return {
                "classButtonStyle": "button--oval--",
                "contents": contents,
                "contentsType": "create",
                "input": {
                    "keyword_name": "",
                    "similar_keywords": [],
                },
            };
        },
        "computed": {
            "arrayKeywords": {
                // 配列データをブラウザでカンマ区切りとして表示
                get() {
                    return this.input.similar_keywords.join(",");
                },
                set(keywords) {
                    this.input.similar_keywords = keywords.split(",");
                },
            },
        },
        created() {
            if (this.id) {
                // 作成画面から編集画面へ切り替え
                this.contentsType = "update";
                this.fetchingDataKeyword();
            } else {
                this.$_setValidatedImmediate();
            }
        },
        "methods": {
            modalAction(key) {
                if (key === "keywordCreate") {
                    this.handleCreate();
                } else if (key === "keywordUpdate") {
                    this.handleUpdate();
                } else if (key === "keywordDelete") {
                    this.handleDelete();
                } else if (key === "keywordCreate--finished") {
                    this.$router.push({ "name": "similar_keywords_list" });
                } else if (key === "keywordUpdate--finished") {
                    this.$router.push({ "name": "similar_keywords_list" });
                } else if (key === "keywordDelete--finished") {
                    this.$router.push({ "name": "similar_keywords_list" });
                }
            },
            fetchingDataKeyword() {
                // 個別類似キーワードデータ取得
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/similar_keywords/${this.id}`,
                    this.$_requestConfig()
                ).then(response => {
                    this.input = response.data;
                    this.$_setValidatedImmediate();
                }).catch(() => {
                    this.$_modalError("keywordFetchError");
                });
            },
            handleCreate() {
                // 個別類似キーワードデータ作成
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/similar_keywords`,
                    this.input,
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
            handleUpdate() {
                // 個別類似キーワードデータ更新
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/similar_keywords/${this.id}`,
                    this.input,
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
            handleDelete() {
                // 個別類似キーワードデータ削除
                common.$_axios.delete(
                    `${common.$_configs.apiUrl}/similar_keywords/${this.id}`,
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
        },
    };
</script>
